import type { PropsWithChildren } from "react";
import clsx from "clsx";
import React from "react";
import Link from "~/components/link";

export type BrandProps = {
  to?: string;
  external?: boolean;
  label: React.ReactNode;
};
interface Props extends PropsWithChildren<unknown> {
  rightMenu?: React.ReactNode;
  brand?: BrandProps;
  id?: string;
  rightId?: string;
  secondary?: boolean;
  tertiary?: boolean;
}

export default function NavBar({
  rightMenu,
  secondary,
  id,
  rightId = "right-menu-portal",
  brand,
  children
}: Props) {
  return (
    <nav
      className={clsx(
        "navbar navbar-static-top",
        secondary && "navbar-default sub-navbar",
        !secondary && "navbar-inverse",
        !secondary && import.meta.env.DEV && "navbar-inverse-dev"
      )}
    >
      {brand && (
        <div className="navbar-header">
          {brand.to ? (
            <Link
              className="navbar-brand"
              to={brand.to}
              external={brand.external}
            >
              {brand.label}
            </Link>
          ) : (
            <span className="navbar-brand">{brand.label}</span>
          )}
        </div>
      )}
      <div className="navbar-collapse collapse" id={id}>
        <ul className="nav navbar-nav">{children}</ul>
        <ul
          className="nav navbar-nav navbar-right"
          id={secondary ? rightId || "right-menu-portal" : undefined}
        >
          {rightMenu}
        </ul>
      </div>
    </nav>
  );
}
